
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { PersonsDetails } from '@/store/persons/state'
  import { Details } from '@/utils/generalInterface'
  import { isEmpty } from '@/utils/general'
  import { mapActions, mapGetters } from 'vuex'
  import { PersonFormatted } from '@/store/persons/person'

@Component({
  components: {},
  methods: {
    isEmpty,
    ...mapActions('persons', ['findReferencePerson']),
  },
  computed: {
    ...mapGetters('persons/reference', ['relationShips']),
    ...mapGetters('persons', ['referencePerson', 'disabledReferenceField']),
  },
})
  export default class PhoneReferenceForm extends Vue {
  person: PersonsDetails = {
    id: null,
    phone: '',
    name: '',
    lastName: '',
    surname: '',
    lastSurname: '',
    relationship: '',
  };

  findReferencePerson!: (phone: string) => void;
  relationShips!: Details[];
  referencePerson!: PersonFormatted;
  disabledReferenceField!: boolean;
  @Prop({ type: String, default: '' }) accountHolder!: string;
  @Prop({ type: Object, default: () => ({}) }) value!: PersonsDetails;

  get keys (): string {
    return this.person.phone ? this.person.phone : 'clean'
  }

  findPhone (phone: string): void {
    if (!isEmpty(phone) && phone.length > 5) {
      this.person.id = null
      this.person.name = ''
      this.person.lastName = ''
      this.person.surname = ''
      this.person.lastSurname = ''

      this.findReferencePerson(phone)
    }
  }

  @Watch('referencePerson', { immediate: true, deep: true })
  onReferencePersonChange (val: PersonFormatted): void {
    if (val) {
      this.person.id = val.id
      this.person.name = val.name
      this.person.lastName = val.secondName
      this.person.surname = val.surname
      this.person.lastSurname = val.secondSurname
    }
  }

  @Watch('value') onChange (val: PersonsDetails): void {
    if (val) {
      this.person = val
    } else {
      this.person = {} as PersonsDetails
    }
  }

  @Watch('person', { immediate: true, deep: true })
  onPersonChange (val: PersonsDetails): void {
    this.$emit('input', val)
  }
  }
